require('../css/app.scss');

var jQuery = require('jquery')
require('bootstrap')
var hljs = require('highlightjs')

hljs.initHighlightingOnLoad();

jQuery(document).ready(function() {
    jQuery('a.view').on('click', function () {
        var $pre = jQuery(this).parent().next('pre');
        if ($pre.hasClass('fold')) {
            $pre.removeClass('fold').addClass('full');
            jQuery(this).text('Hide');
        } else if ($pre.hasClass('full')) {
            $pre.removeClass('full').addClass('fold');
            jQuery(this).text('View all');
        }
        return false;
    });
})
